import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import Colors from 'theme/colors'
import Screens from 'theme/screens'
import { useText } from 'texts'

import MenuIcon from './MenuIcon'
import NavLink from './NavLink'
import Logo from './Logo'

export const headerHeight = 88

const backgroundColorFadeCss = css`
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
`

const StyledHeader = styled.header`
  display: flex;
  position: fixed;
  height: ${headerHeight}px;
  justify-content: center;
  left: 0;
  z-index: 5;
  width: 100%;
  ${({ menuOpen }) => !menuOpen && backgroundColorFadeCss};
  background-color: ${({ isScrolling, menuOpen }) =>
    rgba(Colors.NEUTRAL_100, isScrolling || menuOpen ? 1 : 0.85)};
  box-shadow: ${({ isScrolling, menuOpen }) => {
    if (menuOpen) return
    return isScrolling
      ? `0 1px 0 0 ${rgba(Colors.GRAY_900, 0.16)};`
      : '0px 2px 4px rgba(0, 0, 0, 0.04);'
  }};
`

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  height: ${headerHeight}px;
  flex-grow: 1;
  justify-content: flex-end;
`

const Menu = styled.div`
  @media (max-width: ${Screens.sm}) {
    display: flex;
    position: fixed;
    height: ${props => (props.open ? 'auto' : '0%')};
    top: ${headerHeight}px;
    left: 0;
    right: 0;
    background-color: ${Colors.NEUTRAL_100};
    z-index: 5;
    transition: height 0.5s ease;
    overflow: hidden;
    align-items: flex-start;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  }
`

const NavLinks = styled.div`
  align-items: center;
  display: flex;

  @media (max-width: ${Screens.sm}) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 24px;

    a {
      margin-bottom: 24px;
    }
  }
`

const Header = () => {
  const { t } = useText()
  const [scrolling, setScrolling] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleScroll = useCallback(() => {
    setScrolling(window.scrollY > 0)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <StyledHeader isScrolling={scrolling} menuOpen={menuOpen}>
      <Container className="container">
        <Logo />

        <Nav>
          <MenuIcon
            open={menuOpen}
            onClick={() => setMenuOpen(open => !open)}
          />
          <Menu open={menuOpen}>
            <NavLinks>
              <NavLink to="/">{t('pages.home')}</NavLink>
              <NavLink to="/como-funciona">{t('pages.about')}</NavLink>
              <NavLink to="/quero-usar">{t('pages.access')}</NavLink>
              <NavLink to='https://magaluads.zendesk.com/hc/pt-br' target="_blank">{t('pages.faq')}</NavLink>
              <NavLink to="/termos-de-uso">{t('pages.termsOfUse')}</NavLink>
              <NavLink to="/politica-privacidade">{t('pages.privacyPolicy')}</NavLink>
            </NavLinks>
          </Menu>
        </Nav>
      </Container>
    </StyledHeader>
  )
}

export default Header
