import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Colors from 'theme/colors'

const StyledNavLink = styled(Link)`
  margin-left: 28px;
  text-decoration: none;
  color: ${Colors.NEUTRAL_600};

  &:hover {
    color: black;
  }

  &.active {
    color: ${Colors.PRIMARY_100};
    font-weight: 500;

    &:hover {
      color: #0163bb;
    }
  }
`

const NavLink = props => {
  return <StyledNavLink activeClassName="active" {...props} />
}

export default NavLink
